import React from "react";
import { useState, useEffect } from "react";
import {
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableBody,
  Table,
  Box,
  Tooltip,
  Collapse,
  Button,
  IconButton,
  Stack,
  TablePagination,
  Icon,
} from "@mui/material";
import { useNavigate } from "react-router";
import {
  getNotificationTables,
  getLookup,
} from "../../../services/apiRequests";
import { Modal } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { groupLookup } from "../../../utils/helpers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  setNotificationDetails,
  setDashboardChild,
  setMatchedLookupobj,
  setNotificationCount,
  setNotificationActionAcs,
} from "../../../reduxApp/fieldStore";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Routepath } from "../../../routing/routpath";
import useLoader from "../../../Loader/useLoader";
import { useScreenId } from "../../../hooks/useScreenId";
import { isEmpty } from "lodash";
import AssignModal from "./AssignModal";
import toast from "react-hot-toast";

const columns = [
  "ID",
  "CANDIDATE NAME",
  "REQUESTED BY",
  "REQUESTED DATE",
  "STATUS",
  "ACCESS TYPE",
  "ACTION",
];
let appid = "";
const NotificationTables = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalpages, setTotalPages] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [candidateList, setCandidateDetails] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [assignModal, setassignModal] = useState(false);
  useScreenId(
    6,
    () => {
      getAllNotification();
    },
    []
  );

  const [lookupGroup, setLookupGroup] = useState({});
  const [accessType, setAccessType] = useState({});
  const findFullform = (shortHand) => {
    const longHand = lookupGroup.find((key) => key.value === shortHand);
    return longHand.label;
  };
  const access = (shortHand) => {
    const longHand = accessType.find((key) => key.value === shortHand);
    return longHand.label;
  };

  const getAllNotification = async () => {
    try {
      showLoader();
      const dataRespond = await getNotificationTables();
      setCandidateDetails(dataRespond.data.content);
      setTotalPages(dataRespond.data.totalElements);
      dispatch(setNotificationCount(dataRespond.data.totalElements));
      try {
        showLoader();
        const res = await getLookup();

        if (res.status === 200) {
          const groupedLookup = groupLookup(res.data);
          setLookupGroup(groupedLookup["CANDIDATE_STATUS"]);
          setAccessType(groupedLookup["ACCESS_TYPE"]);
        }
      } catch (error) {
      } finally {
        hideLoader();
      }
     
    } catch {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };
  const [selectedId, setSelectedId] = useState([]);

  const Title = styled(Box)({
    width: "100%",
    fontWeight: "500",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
    "$ .MuiDataGrid-columnHeaderRow": { backgroundColor: "yellow" },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleClose = () => {
    setassignModal(false);
  };
  return (
    <Title>
      <h3 style={{ marginBottom: "20px" }}>NOTIFICATION DASHBOARD</h3>
      {!isEmpty(candidateList) && !isEmpty(lookupGroup) && (
        <TableContainer sx={{ width: "90%" }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "1px solid  #D9D9D9",
                  background: "#F7F7F7",
                }}
              >
                {columns.map((rowItem) => (
                  <TableCell
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {rowItem}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {candidateList.map((objItem) => {
                return (
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        cursor: "pointer",
                        padding: "8px 16px 8px 16px",
                      }}
                      onClick={() => {
                        // dispatch(
                        //   setNotificationDetails({
                        //     Event: "View",
                        //     id: objItem.candidateDetailId,
                        //   })
                        // );
                        Navigate(Routepath.NotificationRoute);
                      }}
                    >
                      {objItem.applicationId}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        cursor: "pointer",
                        padding: "8px 16px 8px 16px",
                      }}
                      onClick={() => {
                        dispatch(
                          setNotificationDetails({
                            Event: "View",
                            id: objItem.candidateDetailId,
                          })
                        );
                        Navigate(Routepath.NotificationRoute);
                      }}
                    >
                      {objItem.candidateName}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontSize: "14px",
                        cursor: "pointer",
                        padding: "8px 16px 8px 16px",
                      }}
                      onClick={() => {
                        dispatch(
                          setNotificationDetails({
                            Event: "View",
                            id: objItem.candidateDetailId,
                          })
                        );

                        Navigate(Routepath.NotificationRoute);
                      }}
                    >
                      {objItem.recruiterFirstName} {objItem.recruiterLastName}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        cursor: "pointer",
                        padding: "8px 16px 8px 16px",
                      }}
                      onClick={() => {
                        dispatch(
                          setNotificationDetails({
                            Event: "View",
                            id: objItem.candidateDetailId,
                          })
                        );
                        Navigate(Routepath.NotificationRoute);
                      }}
                    >
                      {objItem.submitDt}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        cursor: "pointer",
                        padding: "8px 16px 8px 16px",
                      }}
                      onClick={() => {
                        dispatch(
                          setNotificationDetails({
                            Event: "View",
                            id: objItem.candidateDetailId,
                          })
                        );
                        Navigate(Routepath.NotificationRoute);
                      }}
                    >
                      {findFullform(objItem.status)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        cursor: "pointer",
                        padding: "8px 16px 8px 16px",
                      }}
                      onClick={() => {
                        dispatch(
                          setNotificationDetails({
                            Event: "View",
                            id: objItem.candidateDetailId,
                          })
                        );
                        Navigate(Routepath.NotificationRoute);
                      }}
                    >
                      {access(objItem.type)}
                    </TableCell>
                    <TableCell
                      sx={{
                        cursor: "pointer",
                        padding: "8px 16px 8px 16px",
                      }}
                    >
                      {
                        <Box>
                          <Stack item container direction={"row"}>
                            <IconButton
                              onClick={() => {
                                setSelectedId(
                                  selectedId === objItem.candidateDetailId
                                    ? ""
                                    : objItem.candidateDetailId
                                );
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>

                            {objItem.status !== "Submited" && (
                              <IconButton
                                sx={{
                                  visibility:
                                    selectedId === objItem.candidateDetailId
                                      ? "visible"
                                      : "hidden",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  dispatch(
                                    setNotificationDetails({
                                      Event: "View",
                                      id: objItem.candidateDetailId,
                                    })
                                  );

                                  dispatch(
                                    setNotificationActionAcs(objItem.type)
                                  );
                                  Navigate(Routepath.NotificationRoute);
                                }}
                              >
                                <Tooltip title="Edit" placement="top">
                                  <ModeEditOutlinedIcon
                                    fontSize="small"
                                    sx={{
                                      color: "#6e57a8",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                            {objItem.status !== "Submited" && (
                              <IconButton
                                sx={{
                                  visibility:
                                    selectedId === objItem.candidateDetailId
                                      ? "visible"
                                      : "hidden",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setassignModal(true);
                                  appid = objItem.applicationId;
                                }}
                              >
                                <Tooltip title="Assign" placement="top">
                                  <AssignmentIndIcon
                                    fontSize="small"
                                    sx={{
                                      color: "#6e57a8",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                          </Stack>
                        </Box>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={totalpages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {assignModal && (
        <AssignModal
          appID={appid}
          setassignModal={setassignModal}
          assignModal={assignModal}
        />
      )}
    </Title>
  );
};

export default NotificationTables;
