import { errorMsg } from "../constance/validityItems";
export function isValid(identityName, keyValues, extraCheck) {
  const onlyAlphabet = /^[a-zA-Z]+$/;
  const alphabetWithspace = /^[A-Za-z ]+$/;
  const onlyNumbers = /^[0-9]*$/;
  const numberWithDecimal = /^[-]?[0-9]+[\.]?[0-9]+$/;
  const regex =
    /^(100000(\.0{1,2})?|([1-9]\d{0,4}(\.\d{1,2})?|0(\.\d{1,2})?))$/;

  switch (identityName) {
    case "candidateName":
    case "candidateJobTitle": {
      return keyValues
        ? alphabetWithspace.test(keyValues)
          ? ""
          : `Enter Valid ${identityName}`
        : errorMsg[identityName];
    }
    case "candidateStartDt": {
      return keyValues === "" ? "Enter Start Date" : "";
    }
    // case "totalCommissionFor24Months": {
    //   return keyValues != ""
    //     ? keyValues >= 0 && keyValues <= 24 && onlyNumbers.test(keyValues)
    //       ? ""
    //       : `Enter Valid ${identityName}`
    //     : errorMsg[identityName];
    // }

    case "recuiterFirstName":
    case "recuiterLastName": {
      return keyValues
        ? onlyAlphabet.test(keyValues)
          ? ""
          : `Enter Valid ${identityName}`
        : errorMsg[identityName];
    }
    case "jobId": {
      return keyValues === "" ? "Enter Job ID" : "";
    }

    case "vacationNoOfDays":
    case "sickLeaveNoOfDays": {
      return keyValues || keyValues === 0
        ? onlyNumbers.test(keyValues) || keyValues === 0
          ? ""
          : `Enter Valid ${identityName}`
        : errorMsg[identityName];
    }
    case "totalCommissionFor24Months": {
      return keyValues != ""
        ? keyValues >= 0 && onlyNumbers.test(keyValues)
          ? ""
          : `Enter Valid ${identityName}`
        : errorMsg[identityName];
    }
    case "perDiemCount": {
      return keyValues || keyValues === 0
        ? keyValues >= 0 && regex.test(keyValues)
          ? ""
          : `Enter Valid ${identityName}`
        : errorMsg[identityName];
    }
    case "comments": {
      return keyValues ? "" : errorMsg[identityName];
    }
    case "leaveStateCd": {
      return extraCheck.candidateDetails === "USA"
        ? keyValues
          ? ""
          : errorMsg[identityName]
        : "";
    }
    case "otherExpenses": {
      return keyValues || keyValues === 0
        ? keyValues >= 0
          ? ""
          : `Enter Valid ${identityName}`
        : errorMsg[identityName];
    }
    case "otherExpensesHourly": {
      return keyValues || keyValues === 0
        ? keyValues >= 0
          ? ""
          : `Enter Valid ${identityName}`
        : errorMsg[identityName];
    }

    case "candidateBillRate":
    case "refferalAmount":
    case "payRate": {
      return keyValues || keyValues === 0
        ? keyValues >= 0
          ? ""
          : `Enter Valid payRate`
        : "Enter payRate";
    }

    // case "candidateBillRate": {
    //   return keyValues ? "" : errorMsg[identityName];
    // }

    case "candidateBusinessUnit": {
      return keyValues === "" ? " Choose Business Unit" : "";
    }
    case "candidateCountry": {
      return keyValues === "" ? " Choose Working Location" : "";
    }
    case "candidateTypeOfResource": {
      return keyValues === "" ? " Choose Type of Resource" : "";
    }
    case "vmsFee": {
      return keyValues === "" ? "Choose VMS Fee" : "";
    }
    case "preId": {
      return keyValues === "" ? "Choose Pre Id" : "";
    }
    case "holidays": {
      return keyValues === "" ? "Choose Holidays" : "";
    }
    case "candidateInsurenceType": {
      return keyValues === "" ? " Choose Insurance Type" : "";
    }

    case "immgirationType": {
      return keyValues === "" ? "Choose Immigration" : "";
    }
    case "tnVisaPremiumYN": {
      return extraCheck.immgirationType === "4000"
        ? !keyValues
          ? "Please Choose Fee type"
          : ""
        : "";
    }
    case "tnVisaCountry": {
      return extraCheck.immgirationType === "4000"
        ? !keyValues
          ? "Please Choose country"
          : ""
        : "";
    }
  }
}
