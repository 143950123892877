import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import Dashboard from "../dashboard/DashboardTables";
import { AccExedashboardData } from "../../../services/apiRequests";
import { useSelector } from "react-redux";
import { getAuthorization } from "../../../selectors/authSelectors";
import AccDashboard from "../accDashboard";
import useAuth from "../../../hooks/useAuth";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

const DashboardTabsAll = () => {
  const currentTab = sessionStorage.getItem("dashboardTabValue") || "1";
  const [activeTab, setActiveTab] = useState(currentTab);
  const loginDetials = useSelector(getAuthorization);
  // const token = useSelector((state) => state?.auth?.token);
  const { getAccess } = useAuth();

  const { noAccess: noAccessAEDashboard, canReadWrite: AccessAEDashboard } =
    getAccess("23");

  const onTabChange = (_, index) => {
    setActiveTab(index);
    sessionStorage.setItem("dashboardTabValue", index);
  };

  return (
    <TabContext value={activeTab}>
      <TabList
        onChange={onTabChange}
        value={activeTab}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Tab
          label="My Dashboard"
          value="1"
          sx={{ textTransform: "capitalize" }}
        />
        {AccessAEDashboard === true ? (
          <Tab
            label="AE Dashboard"
            sx={{ textTransform: "capitalize" }}
            value="2"
          />
        ) : null}
      </TabList>
      <TabPanel value="1" index={0}>
        <Dashboard />
      </TabPanel>
      <TabPanel value="2" index={1}>
        <AccDashboard />
      </TabPanel>
    </TabContext>
  );
};

export default DashboardTabsAll;
