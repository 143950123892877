import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SummaryReports from "./SummaryReports";
import MonthlyCommision from "./MonthlyCommissionReport";
import { getLookup } from "../../../services/apiRequests";
import { groupLookup } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import CommonContainer from "../../../CommonContainer/CommonContainer";
import {
  setDashboardChild,
  updateCandidateDetails,
} from "../../../reduxApp/fieldStore";
import { useNavigate } from "react-router-dom";
import { dashboard_api } from "../../../constance/DashboardJson";
import { Routepath } from "../../../routing/routpath";

const ReportsComp = () => {
  const [lookup, setLookup] = useState([]);
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.dropedField?.filter);
  const [basicgetData, setBasicGetData] = useState(filterData);
  const currentTab = sessionStorage.getItem("tabValue") || "1";
  const [value, setValue] = useState(currentTab);
  const navigate = useNavigate();

  useEffect(() => {
    getLookups();
  }, []);

  async function getLookups() {
    const res = await getLookup();
    if (res.status === 200) {
      const extractedData = groupLookup(res.data);
      setLookup(extractedData);
    }
  }

  const routeToEV = (userAction, id, component) => {
    dispatch(updateCandidateDetails({ ...dashboard_api }));
    dispatch(
      setDashboardChild({
        userAction: "Post",
        id: "",
        userName: "",
      })
    );
    navigate(Routepath.RateCalculatorTabView);
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("tabValue", newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "98%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Summary"
                value="1"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Monthly Commission "
                value="2"
                sx={{ textTransform: "capitalize" }}
              />
            </TabList>
          </Box>
          <CommonContainer
            DisplayAddnew={true}
            DisplaySearch={false}
            handleChange={routeToEV}
            header="Rate Calculator Report"
            addCalc="Add Calc"
          />
          <TabPanel value="1">
            <SummaryReports
              tabValue={value}
              extractedData={lookup}
              setBasicGetData={setBasicGetData}
              basicgetData={basicgetData}
            />
          </TabPanel>
          <TabPanel value="2">
            <MonthlyCommision
              tabValue={value}
              extractedData={lookup}
              setBasicGetData={setBasicGetData}
              basicgetData={basicgetData}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default ReportsComp;
