import React, { useEffect, useState } from "react";
import {
  setAeNotificationDetails,
  setscreenId,
} from "../../../reduxApp/fieldStore";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routepath } from "../../../routing/routpath";
import AeNotificationTable from "./AeNotificationTable";
import { Box, Typography } from "@mui/material";

const AeNotification = () => {
  const dispatch = useDispatch();
  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();

  const viewMydetails = (userAction, data) => {
    dispatch(setscreenId(3));
    dispatch(
      setAeNotificationDetails({
        Event: userAction,
        id: data.candidateDetailId,
      })
    );
    navigate(Routepath.OnBording);
  };

  return (
    <Box p={2}>
      <Typography variant="h4" sx={{ textAlign: "center", padding: "2rem" }}>
        On Boarded Dashboard
      </Typography>
      <AeNotificationTable
        pageState={pageState}
        setPageState={setPageState}
        handleAction={viewMydetails}
      />
    </Box>
  );
};

export default AeNotification;
